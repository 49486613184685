.content {
    position: absolute;
    width: 85%;
    height: 85%;

    max-width: 1500px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    box-shadow: 0 0 15px var(--shadow-main);
    background-color: var(--background-main);

    border-left: solid 10px var(--accent-main);
}

.side-bar {
    position: absolute;
    width: 300px;
    height: 100%;

    left: 0;

    border-right: solid 1px var(--border-main);

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
}

.side-bar div {
    width: 90%;
    border-left: solid 1px var(--border-main);
    margin: 3px;
}

.side-bar .teacher {
    width: auto;
    border: none;
    margin: 5px;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side-bar h1 {
    margin: 10px;
    font-size: 30px;
    font-family: 'semi-bold';
}

.teacher h2 {
    margin-left: 10px;
    opacity: 65%;
    cursor: pointer;
    transition: opacity .2s ease-in-out;
}

.teacher h3 {
    margin: 2px;
    margin-left: 10px;
    font-size: 13px;
}

.teacher h2:hover {
    opacity: 100%;
}

.main {
    position: absolute;
    width: calc(100% - 300px);
    height: 100%;
    left: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.left {
    position: absolute;
    width: 55%;
    height: 100%;

    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;

}

.left h1 {
    font-size: 20px;
    margin: 10px;
    padding: 10px;

    text-align: center;
}

.actions {
    width: 85%;
    height: 30px;

    align-self: center;

    padding-bottom: 10px;
    margin-bottom: 10px;

    border-bottom: solid 1px var(--border-main);

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.actions div {
    width: 49%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    opacity: 50%;
    transition: opacity .2s ease-in-out;

    cursor: pointer;
}

.actions div:hover {
    opacity: 100%;
}

.actions .icon {
    width: 15px;
    height: 15px;
    margin: 5px;

    background-image: url(../../assets/img/external-link.svg);
    background-size: contain;
    background-repeat: no-repeat;

    pointer-events: none;
}

.actions h2 {
    font-size: 15px;
    pointer-events: none;
}

.about-me,
.task-list,
.contact-info {
    width: 75%;
    margin: 10px;
    padding: 10px;

    border-left: solid 3px var(--accent-main);
}

.about-me h2,
.task-list h2,
.contact-info h2,
.comments h2 {
    color: var(--font-h1);
    margin-bottom: 10px;
    font-size: 17px;
}

.about-me h3,
.task-list h3,
.contact-info h3,
.comments h3 {
    font-size: 15px;
    color: var(--font-h2);
    margin-bottom: 10px;

    opacity: 75%;
}

.comments {
    width: 75%;
    margin: 10px;
    padding: 10px;

    align-self: center;

    border-left: solid 3px var(--accent-main);
}

.comments h3 {
    padding: 10px;
    margin: 10px;

    border-radius: 5px !important;
    border: solid 1px var(--border-main);

    padding-bottom: 30px;

    color: var(--font-h2);
    font-family: 'arial';
    opacity: 75%;

    font-size: 13px;
}

.right {
    position: absolute;
    width: 43%;
    height: 103%;
    left: 55%;

    margin-left: -2px;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
    background-color: var(--background-darker);

    box-shadow: 0 0 15px var(--shadow-main);
}

.teacher-adjectives {
    width: 85%;
    height: auto;

    padding: 10px;
    margin: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.adjective-list {
    width: 100%;
    height: 100%;

    border-left: solid 5px var(--accent-main);

    display: flex;
    flex-direction: column;
}

.adjective-list h1 {
    margin: 10px;
    padding: 10px;

    text-shadow: 0 0 15px var(--shadow-darker);

    font-size: 20px;

    width: 80%;

    align-self: center;

    text-align: center;
    border-bottom: solid 1px var(--border-darker);
}

.adjective-list h3 {
    margin: 15px;
    padding: 5px;

    font-size: 13px;
    color: var(--font-h2);
}

.adjective-list h3 strong {
    color: VAR(--font-h1);
}

.adjective {
    margin: 10px;
    padding: 5px;
    max-width: 93%;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: var(--accent-main);

    border-radius: 5px !important;
}

.adjective h2 {
    padding: 5px;
    font-size: 12px;
    color: var(--font-h4);
}

.adjective h3 {
    padding: 0;
    margin: 0;
    margin-left: 5px;
    opacity: 40%;

    font-size: 10px;

    color: var(--font-h4);
}

.teaching-style {
    font-size: 20px;
    width: 65%;
    text-align: center;
    text-shadow: 0 0 15px var(--shadow-darker);

    margin: 20px;
}

.teacher-card {
    width: 85%;

    margin: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    width: 100%;
    height: 350px;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card .title {
    margin-top: -300px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title h3 {
    color: var(--font-h2);
    margin: 2px;
}

.card h6 {
    display: flex;
    position: absolute;
    font-size: 18px;
    font-family: 'semi-bold';
    color: var(--font-h1);
}

.circular-value {
    position: absolute;
    margin: 20px;
    width: 200px;
    height: 200px;

    border-radius: 50% !important;

    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-value .pointer {
    position: absolute;
    height: 50%;
    width: 2px;
    background-color: var(--background-darker);
    padding-top: 10px;

    margin-bottom: 50%;

    transform-origin: 50% 100%;
}

.pointer:nth-child(2) {
    transform: rotateZ(120deg);
}

.pointer:nth-child(3) {
    transform: rotateZ(-120deg);
}

.circular-value h2 {
    position: absolute;
    font-size: 14px;
    font-family: 'semi-bold';

    color: var(--font-h1);

    text-shadow: 0 0 15px var(--shadow-darker);
    z-index: 5;

    width: 100px;
    text-align: center;
}

.circular-value h2:nth-child(4) {
    top: 50px;
    right: -55px;
    rotate: 70deg;
}

.circular-value h2:nth-child(5) {
    bottom: -20px;
}

.circular-value h2:nth-child(6) {
    top: 50px;
    left: -55px;
    rotate: -70deg;
}

.inner-circle {
    position: absolute;
    width: 85%;
    height: 85%;

    background-color: var(--background-darker);

    border-radius: 50% !important;

    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner-circle h1 {
    font-size: 17px;
    margin: 5px;
    text-shadow: 0 0 10px var(--shadow-darker);
}

.inner-circle h3 {
    pointer-events: none;
}

.teacher-loading-screen {
    height: 100%;
    width: 100%;
}

.teacher-placeholder {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 1400px) {
    .content {
        width: 98%;
    }
}


@media screen and (max-height: 750px) {
    .container {
        justify-content: flex-end;
    }

    .content {
        height: calc(100% - 50px - 7%);
        bottom: 3.5%;
    }
}