.content {
    position: absolute;
    width: 80%;
    height: 80%;

    max-width: 1300px;
    max-height: 1200px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.top-container {
    position: absolute;
    width: 100%;
    height: 50%;

    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.top-container .left {
    position: absolute;
    width: calc(100% - 250px - 300px);
    height: 100%;

    right: calc(250px + 300px);
}

.top-container.full .left {
    height: 200%;

    align-self: flex-start;
}

.top-container .middle {
    position: absolute;
    width: 250px;
    height: 110%;

    right: 300px;

    background-color: var(--info-background);

    box-shadow: 0 0 15px var(--shadow-main);

    z-index: 2;
}

.top-container .right {
    position: absolute;
    width: 300px;
    height: 100%;

    right: 0;

}

.bottom-container {
    position: absolute;
    width: 100%;
    height: 50%;

    bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.top-container .left,
.top-container .right,
.bottom-container .left,
.bottom-container .middle,
.bottom-container .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bottom-container .left {
    position: absolute;
    height: 100%;
    width: calc(85% - 300px - 250px);
    right: calc(300px + 250px);
}

.bottom-container .middle {
    position: absolute;
    height: 90%;
    width: 250px;
    right: 300px;
}

.bottom-container .right {
    position: absolute;
    height: 100%;
    width: 300px;
    right: 0;
}

.messages,
.news,
.schedule {
    position: absolute;
    width: 95%;
    height: 95%;

    box-shadow: 0 0 15px var(--shadow-main);
    background-color: var(--background-main);

    display: flex;
    flex-direction: column;

    border-left: solid 5px var(--accent-main);

    overflow-y: scroll;
    overflow-x: hidden;
}

.grades {
    position: absolute;
    width: 95%;
    height: 95%;


    display: flex;
    flex-direction: column;
    align-items: center;
}

.yo-container {
    margin-top: 2.5%;
    width: 95%;
    height: auto;


    box-shadow: 0 0 10px var(--shadow-main);
    background-color: var(--background-main);

    border-left: solid 5px var(--accent-main);

    margin-bottom: 20px;
}

.table-title {
    font-size: 22px;
    margin: 5px;
    padding: 5px;
    width: 90%;

    font-family: 'semibold';

    display: inline-block;
    text-align: center;
    text-decoration: none;

    color: var(--font-h1);
    margin: 10px;
}

.table-title:hover {
    opacity: 75%;
}

.grade-container {
    width: 95%;
    height: 100%;


    height: auto;
    box-shadow: 0 0 10px var(--shadow-main);
    background-color: var(--background-main);

    border-left: solid 5px var(--accent-main);

    overflow-y: scroll;
    overflow-x: hidden;
}

.grade-data {
    display: flex;
    flex-direction: column;
}

.yo-column {
    display: flex;
    flex-direction: row;

    justify-content: space-evenly;

    margin: 5px;
}

.yo-row {
    width: 25%;
    height: auto;

    padding: 2px;

    font-size: 17px;

    border: solid 1px var(--border-main);
    margin-left: 2px;
    margin-right: 2px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.yo-row:nth-child(1) {
    width: 40%;
}

.yo-row:nth-child(2) h2 {
    font-family: 'bold';
}

.yo-column.head .yo-row {
    border: none;
}

.yo-column.head .yo-row h1,
.yo-column.head .yo-row h2 {
    font-family: 'semi-bold' !important;
    color: var(--font-h2);

    font-style: italic;
}

.yo-row h1 {
    font-size: 15px;
    text-align: center;
}

.message-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.messages a {
    font-size: 25px;
    width: 90%;
    text-align: center;
    padding: 5px;
    margin: 5px;

    cursor: pointer;
    user-select: none;

    color: var(--font-h1);
    text-decoration: none;
    font-family: 'semi-bold';
}

.messages a:hover {
    opacity: 50%;
}

.messages h2 {
    align-self: center;

    width: 75%;

    text-align: center;
    font-family: 'semi-bold';

    padding: 5px;
    margin: 5px;

    border-bottom: solid 1px var(--border-main);
}

.messages h3 {
    max-width: 75%;

    width: 100%;
    align-self: center;
    text-align: center;
}

.message-object {
    margin: 5px;
    padding: 10px;

    width: calc(100% - 40px);

    border: solid 1px var(--border-main);
    border-radius: 5px;

    cursor: pointer;
}

.message-object.new h6 {
    width: 30px;
    text-align: center;
    color: var(--font-h4);

    font-size: 12px;

    background-color: var(--accent-main);
    padding: 4px;
    margin: 5px;

    border-radius: 4px;
}


.message-object h1 {
    font-size: 17px;
    text-align: left;
    margin: 2px;

    border: none;

    transition: opacity .2s ease-in-out;
}

.message-object:hover>h1 {
    opacity: 50%;
}

.message-object h3 {
    margin: 5px;
    max-width: unset;
    text-align: start;
}

.message-object h4 {
    margin-left: 5px;
    font-family: 'semi-bold';
    color: var(--font-h2);
}

.news a {
    font-size: 25px;
    width: 90%;
    text-align: center;
    padding: 5px;
    margin: 5px;

    cursor: pointer;
    user-select: none;

    color: var(--font-h1);
    text-decoration: none;
    font-family: 'semi-bold';
}

.news a:hover {
    opacity: 50%;
}


.news-object {
    margin: 5px;
    padding: 10px;

    cursor: pointer;
}

.news-object.disabled {
    cursor: not-allowed;
}

.news-object.disabled h1 {
    opacity: 100% !important;
}

.news-object h1 {
    font-size: 17px;
    text-align: left;
    margin: 2px;

    pointer-events: none;

    transition: opacity .2s ease-in-out;
}

.news-object:hover h1 {
    opacity: 50%;
}

.news-object h3 {
    padding: 5px;
    margin: 5px;
    pointer-events: none;

    border-left: solid 1px var(--border-main);
}

.news-object h4 {
    margin-left: 5px;
    font-family: 'semi-bold';
    pointer-events: none;
}

.news-object h5 {
    font-size: 12px;
    color: var(--error);
    margin-left: 5px;
}

.news-object h2,
.news-object h3 {
    pointer-events: none;
}

.grades ul a:nth-child(2) {
    font-family: 'semi-bold' !important;
}

.grades .title {
    font-size: 25px;
    width: 90%;
    text-align: center;
    padding: 5px;
    margin: 5px;

    cursor: pointer;
    user-select: none;

    color: var(--font-h1);
    text-decoration: none;
    font-family: 'semi-bold';
}

.grades .title:hover {
    opacity: 50%;
}


.grades .overview {
    padding: 10px;
    margin: 5px;

    border-left: solid 1px var(--border-main);

    margin-left: 20px;
}

.overview ul {
    padding: 5px;
}

.grade-object {
    margin: 5px;
    margin-left: 20px;
}

.grade-object h1 {
    font-size: 17px;
    text-align: start;
    border: none;
    margin: 2px;

}

.grade-object ul {
    margin-left: 15px;
}

.side-bar {
    position: absolute;
    width: 40px;
    height: 98%;

    background-color: var(--accent-main);
    box-shadow: 0 0 15px var(--shadow-main);

    left: -25px;

    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.side-bar-content {
    position: absolute;
    width: 300px;
    rotate: 90deg;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.side-bar-content h5 {
    color: var(--font-h4);
    cursor: pointer;
}

.side-bar-content h5:hover {
    font-family: 'bold';
}

.side-bar-content .selected {
    font-family: 'bold';
    pointer-events: none;
}

.schedule {
    width: 97%;
    height: 97%;
    display: flex;
    flex-direction: column;
}

.week {
    width: 100%;

    padding-bottom: 20px;
    margin-bottom: 20px;

    border-bottom: solid 1px var(--border-main);
}

.week .week-caption {
    height: 30px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.week-caption h1 {
    font-size: 20px;
}

.week .days {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
}

.day {
    width: calc((100% / 5));
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date {
    margin-top: 5px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hour {
    /* this was a mistake */
    position: absolute;

    width: calc((100% / 5 - 5px));
    min-height: 55px;

    margin-bottom: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--schedule-main);

    transition: all .4s ease-in-out, transforn .1s ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.day .hour:hover {
    transform: scale(1.07);
}

.hour h1 {
    text-shadow: 0 0 7px var(--shadow-darker);

    color: var(--schedule-h1);
    margin: 10px;
    font-size: 10px;

    align-self: flex-start;
}

.data {
    height: 90%;
    width: 90%;

    display: flex;
    flex-direction: column;
}

.data .group {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.data a,
.data h2 {
    text-shadow: 0 0 7px var(--shadow-darker);
    color: var(--schedule-h1);
    font-size: 11px;
    padding: 5px;
    padding-top: 0;
    padding-bottom: 0;

    text-decoration: none;

    pointer-events: all;
    font-family: 'semi-bold';
    transition: opacity .2s ease-in-out;

    min-width: 15px;

    opacity: 50%;
}

.data .code {
    opacity: 75%;
}

.data a {
    font-weight: 'bold';
}

.data a:hover {
    opacity: 100%;
}


.data h3 {
    font-size: 10px;
    width: 100%;
    padding-bottom: 2px;

    color: var(--error);
    opacity: 75%;
}


.loading-screen {
    width: 100%;
    height: 100%;
}

.grade-loading-screen {
    height: 95%;
    width: 95%;
    
    box-shadow: 0 0 15px var(--shadow-main);
    background-color: var(--background-main);

    background-position: center;
    background-size: 64px 64px;
    background-repeat: no-repeat;

    background-image: url(../../assets/img/loading-book.gif);


    border-left: solid 5px var(--accent-main);
}

.homework {
    position: absolute;
    right: 0;
    width: 97%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.homework-loading-screen {
    width: 100%;
    height: 100%;
}

.homework h1 {
    font-size: 22px;
    margin: 10px;
    padding: 10px;
}

.homework .course-list {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* lops hashset [L2021 & L2016] */
.c-type25,
.c-type136 {
    background-color: var(--L2021-mandatory-main) !important;
}

.c-type25-graded,
.c-type136-graded {
    background-color: var(--L2021-mandatory-selected) !important;
}


.c-type137 {
    background-color: var(--L2021-g-optional-main) !important;
}

.c-type137-graded {
    background-color: var(--L2021-g-optional-selected) !important;
}

.c-type138 {
    background-color: var(--L2021-diploma-main) !important;
}

.c-type138-graded {
    background-color: var(--L2021-diploma-selected) !important;
}

.c-type141,
.c-type146,
.c-type151 {
    background-color: var(--L2021-l-optional-main) !important;
}

.c-type141-graded,
.c-type146-graded,
.c-type151-graded {
    background-color: var(--L2021-l-optional-selected) !important;
}

.unknown {
    background-color: gray !important;
}

.c-type112,
.c-type66,
.c-type26,
.c-type65 {
    background-color: var(--L2016-l-optional-main) !important;
}

.c-type26-graded,
.c-type66-graded,
.c-type26-graded,
.c-type65-graded {
    background-color: var(--L2016-l-optional-selected) !important;
}

.c-type27 {
    background-color: var(--L2016-g-optional-main) !important;
}

.c-type27-graded {
    background-color: var(--L2016-g-optional-selected) !important;
}

.c-type119,
.c-type29 {
    background-color: var(--L2021-diploma-main) !important;
}

.c-type119-graded,
.c-type29-graded {
    background-color: var(--L2021-diploma-selected) !important;
}

.group-object {
    width: 100%;
    margin: 5px;

    display: flex;
    flex-direction: row;
}

.group-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 150px;
    max-width: 150px;
    padding: 10px;

    border-radius: 5px;
}

.group-code h2 {
    font-size: 20px;
    color: var(--schedule-h1);
    font-family: 'bold';
    padding: 2px;
    text-align: center;
}

.group-code h4 {
    color: var(--schedule-h1);
    text-align: center;
    font-style: italic;
}

.group-code h3 {
    color: var(--schedule-h1);
    opacity: 50%;
    padding: 3px;
    font-size: 13px;
    text-align: center;
}

.group-object h5 {
    font-family: 'bold';
    padding: 5px;
    color: var(--font-h1);
    font-size: 20px;
}

.group-object .homework-list {
    width: 100%;

    border-left: solid 1px var(--border-main);
    padding-left: 5px;
    margin-left: 10px;
}

.group-object .homework-action {
    font-size: 15px;
    font-family: 'regular';
    color: var(--font-h2);
    position: absolute;
    text-align: right;

    right: 10px;

    opacity: 50%;

    cursor: pointer;

    width: 100px;
    user-select: none;
}

.homework-action:hover {
    opacity: 100%;
}

.homework-object {
    margin-bottom: 10px;
}

.homework-object h2 {
    color: var(--font-h1);
    font-family: 'semi-bold';
    font-size: 16px;
    padding: 5px;
}

.homework-object h3 {
    color: var(--font-h3);
    font-family: 'arial';
    font-style: italic;

    font-size: 15px;
    padding: 2px;
    padding-left: 10px;
}

.top-container .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info {
    width: 90%;
    position: absolute;
    top: calc(30px + 150px + 10px);
    height: calc(100% - 30px - 150px - 10px);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.info h1 {
    color: var(--info-h1);
    font-size: 15px;
    margin: 10px;
}

.info h2 {
    color: var(--info-h1);
    font-size: 18px;
    margin: 10px;

    text-align: center;
}



.links {
    position: absolute;
    width: 95%;
    height: 98%;
    top: 5%;

    box-shadow: 0 0 15px var(--shadow-main);
    background-color: var(--background-main);

    display: flex;
    flex-direction: column;

    border-left: solid 5px var(--accent-main);

    overflow-y: scroll;
    overflow-x: hidden;
}

.links h3 {
    padding: 10px;
    color: var(--font-h1);
    font-family: 'semi-bold';
    font-size: 16px;
}

.links h1 {
    font-size: 20px;
    margin: 5px;
    padding: 5px;
}

.links .link {
    width: 90%;
    margin-left: 5px;
    align-self: center;

    border-left: solid 1px var(--border-main);
}

.link {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.link a {
    margin: 2px;
    font-size: 15px;
    cursor: pointer;

    color: var(--font-h2);
    text-decoration: underline;
}


/* clock */
.clock {
    position: absolute;
    width: 150px;
    height: 150px;

    top: 30px;

    border-radius: 50%;

    border: solid var(--clock-markings) 3px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--clock-background);
}

.clock-marking-x {
    position: absolute;
    width: 90%;
    height: 3px;
    background-color: var(--clock-markings);
}

.clock-marking-y {
    position: absolute;
    width: 90%;
    height: 3px;
    background-color: var(--clock-markings);

    transform: rotate(90deg);
}

.clock-marking {
    position: absolute;
    width: 90%;
    height: 2px;
    background-color: var(--clock-markings);
    opacity: 75%;
}

.clock-marking.one {
    transform: rotate(-30deg);
}

.clock-marking.two {
    transform: rotate(-60deg);
}

.clock-marking.two {
    transform: rotate(-60deg);
}

.clock-marking.three {
    transform: rotate(30deg);
}

.clock-marking.four {
    transform: rotate(60deg);
}

.clock .inner-circle {
    position: absolute;
    width: 70%;
    height: 70%;
    background-color: var(--clock-background);

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.hand {
    position: absolute;
    top: 50%;

    transform-origin: 50% 0;
    /*transform: rotate(180deg);*/

    transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);
}

.hand.sec {
    height: 47%;
    width: 2px;

    background-color: var(--clock-markings);
    opacity: 75%;
    filter: brightness(90%);
}

.hand.min {
    height: 40%;
    width: 3px;

    background-color: var(--clock-markings);
    opacity: 80%;
    filter: brightness(110%);
}

.hand.hour {
    min-height: 0;
    height: 35%;
    width: 4px;

    background-color: var(--clock-markings);
}

.inner-circle .center {
    width: 7px;
    height: 7px;

    background-color: var(--clock-markings);

    border-radius: 50%;
    position: absolute;
}

.schedule-fullscreen {
    position: absolute;
    bottom: 1.7%;
    right: 1.45%;

    height: 25px;
    width: 25px;

    background-color: transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../../assets/img/full-screen.svg);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    opacity: 75%;

    cursor: pointer;
}

.schedule-window {
    position: absolute;
    width: 75%;
    height: 85%;

    max-width: 1200px;
    max-height: 1300px;

    box-shadow: 0 0 15px var(--shadow-main);

    background-color: var(--background-main);

    padding: 5px;

    z-index: 5;
}

.schedule-loading-screen {
    position: absolute;
    width: 100%;
    height: 100%;

    z-index: 5;
}

.schedule-left {
    position: absolute;
    left: 10px;
    top: 50%;
}

.schedule-right {
    position: absolute;
    right: 10px;
    top: 50%;
}

.schedule-left,
.schedule-right {
    padding: 10px;
    font-size: 20px;

    border-radius: 4px;

    background-color: var(--accent-main);
    box-shadow: 0 0 15px var(--shadow-main);
    color: var(--font-h5);

    z-index: 6;

    cursor: pointer;
}

.schedule-left:hover,
.schedule-right:hover {
    opacity: 75%;
}

.schedule-info {
    position: absolute;

    top: -20px;
    font-size: 17px;

    opacity: 0;

    color: var(--font-h2);
    text-shadow: 0 0 10px var(--font-h2);

    transition: opacity .3s ease-in-out;

    z-index: 3;
}

.current-info {
    position: absolute;

    left: 10px;
    top: 50px;

    padding: 10px;
    background-color: var(--accent-main);

    box-shadow: 0 0 15px var(--shadow-main);
    border-radius: 4px;

    z-index: 7;
}

.close {
    position: absolute;
    right: 10px;
    bottom: 10px;

    font-size: 20px;
    padding: 2px;

    background: none;

    cursor: pointer;

    z-index: 6;

    color: var(--font-h2);
}

.close:hover {
    opacity: 75%;
}

.current-info h2 {
    color: var(--font-h5);
}

.rows {
    position: absolute;
    width: 100%;
    height: calc(100% - 40px);

    top: 40px;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
}

.weekday-row {
    position: absolute;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-top: -5px;
    height: 45px;

    background-color: var(--accent-main);
    box-shadow: 0 0 15px var(--shadow-main);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 6;
}

.weekday-list {
    width: calc(100% - 20px);
    height: 90%;
    margin-left: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.weekday-list h2 {
    width: calc(100% / 7);
    text-align: center;

    color: var(--font-h5);
}

.schedule-row {
    height: auto;
    width: 100%;

    display: flex;
    flex-direction: row;
}

.schedule-day {
    width: calc(100% / 7);
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.schedule-day .date {
    height: 30px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}


.date .weekend {
    color: var(--error);
}

.event {
    height: auto;
    width: calc(98% - 5px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 2px;
    margin: 2px;

    background-color: var(--background-darker);

    border-left: solid 5px var(--L2021-mandatory-main);
}

.event.full {
    border-left: solid 5px var(--L2021-g-optional-main);
}

.event h4 {
    color: var(--font-h2);
    width: 90%;
    font-size: 12px;
}

.lesson {
    background-color: var(--schedule-main);
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.schedule-day .data {
    width: 95%;
    height: 95%;

    min-height: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    user-select: none;
    margin-bottom: 10px;

    border-left: solid 1px var(--border-main);
    padding-left: 3px;
}

@media screen and (max-height: 1000px) {
    .content {
        top: 95px;
        height: auto;
        max-height: unset;
        justify-content: flex-start;
    }

    .top-container {
        position: relative;
        min-height: 400px;
    }

    .bottom-container {
        position: relative;
        min-height: 400px;
    }
}


@media screen and (max-width: 1400px) {
    .top-container .left {
        position: absolute;
        width: calc(100% - 200px - 220px);
        height: 100%;

        right: calc(200px + 220px);
    }

    .top-container .middle {
        position: absolute;
        width: 220px;
        height: 110%;

        right: 200px;

        background-color: var(--info-background);

        box-shadow: 0 0 15px var(--shadow-main);

        z-index: 2;
    }


    .info {
        position: absolute;
        top: calc(150px + 10px);
        height: calc(100% - 150px - 10px);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .info h1 {
        color: var(--info-h1);
        font-size: 15px;
        margin: 10px;
    }

    .info h2 {
        color: var(--info-h1);
        font-size: 14px;
        margin: 10px;
    }


    .info form button {
        font-size: 13px;
        padding: 5px;
    }

    .top-container .right {
        position: absolute;
        width: 200px;
        height: 100%;

        right: 0;
    }

    .bottom-container {
        width: 100%;
        height: 50%;

        bottom: 0;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .top-container .left,
    .top-container .right,
    .bottom-container .left,
    .bottom-container .middle,
    .bottom-container .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bottom-container .left {
        position: absolute;
        height: 100%;
        width: calc(100% - 220px - 200px);
        right: calc(200px + 220px);
    }

    .bottom-container .middle {
        position: absolute;
        height: 90%;
        width: 220px;
        right: 200px;
    }

    .bottom-container .right {
        position: absolute;
        height: 100%;
        width: 200px;
        right: 0;
    }

    .clock {
        width: 120px;
        height: 120px;
    }

    .data {
        height: 95%;
        width: 95%;

        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .data a,
    .data h2 {
        text-shadow: 0 0 7px var(--shadow-darker);
        color: var(--schedule-h1);
        font-size: 9px;
        padding: 2px;
    }

    .news h1 {
        font-size: 20px;
        padding: 0;
    }

    .news-object h1 {
        font-size: 16px;
    }

    .news-object h2 {
        font-size: 14px;
    }

    .news-object h3 {
        font-size: 12px;
    }

    .messages h1 {
        font-size: 20px;
        padding: 0;
    }

    .message-object h1 {
        font-size: 16px;
    }

    .message-object h2,
    .message-object h4 {
        font-size: 14px;
    }

    .message-object h3 {
        font-size: 12px;
    }

    .grades h1 {
        font-size: 16px;
        padding: 0;
    }

    .grades h2 {
        width: 90%;
        color: var(--font-h1);
        margin-left: 10px;

        font-size: 17px;

        font-family: 'semi-bold';

        text-align: center;
    }

    .grades ul a {
        font-size: 13px;
    }

    .links h3 {
        font-size: 16px;
    }

    .links a {
        font-size: 13px;
    }
}

@media screen and (max-width: 1100px) {
    .content {
        width: 95%;
    }
}