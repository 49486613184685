* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none !important;
    font-size: 100%;
    vertical-align: baseline;
    font: inherit;
    scrollbar-width: thin;
}

:root {
    font-family: 'regular';
    --container: 0px;
}

.container {
    margin-top: var(--container);
}

@font-face {
    font-family: 'bold';

    src: url(https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lppyw7WR32lw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'semi-bold';
    src: url(https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp1s7WR32lw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@font-face {
    font-family: 'regular';
    src: url(https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8WR32lw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Custom Scrollbars */

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    background: var(--background-main);
}

::-webkit-scrollbar-thumb {
    background: var(--background-darker);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--background-darker-hover);
}

h1 {
    color: var(--font-h1);
    font-family: 'semi-bold';
}

h2,
label,
input,
a {
    color: var(--font-h2);
}

h3 {
    color: var(--font-h3);
}

h4 {
    color: var(--font-h4);
}

h5 {
    color: var(--font-h5);
}

strong {
    font-family: 'semi-bold';
}

body {
    background: black;
    overflow: hidden;
}

.loading-screen {
    background-image: url(./assets/img/loading-book.gif);
}

.loading-indicator {
    background-image: url(./assets/img/loading-book.gif);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 85px 85px;

    filter: brightness(0) opacity(100%);
}

.placeholder {
    background-image: url(./assets/img/otakarhu.svg);
    background-position: center center;
    background-size: 256px 256px;
    background-repeat: no-repeat;

    opacity: 5%;
}

.icon {
    filter: brightness(0);
}

.icon-text {
    filter: brightness(0);
}

.container {
    position: absolute;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: var(--background-main);
}

.background {
    position: absolute;
    width: 110%;
    height: 110%;

    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    filter: var(--background-filter);
    pointer-events: none;
}

.disclaimer {
    position: absolute;
    width: 90%;
    left: 5%;
    top: 5%;
    height: 90%;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: none;
}

.disclaimer .icon {
    width: 256px;
    height: 256px;

    background-image: url(/src/assets/img/otakarhu.svg);
    filter: brightness(100%);

    margin: 10px;
}

.disclaimer h1 {
    color: black;
    font-family: 'bold';
    opacity: 75%;
}

.disclaimer strong {
    font-family: 'bold' !important;
}

.printout-footer {
    display: none;
}

@media screen and (max-width: 900px) {
    
    .container {
        display: none;
    }

    .disclaimer {
        display: flex;
    }
}

@media screen and (max-height: 1000px) {
    .container {
        overflow-y: auto;
        overflow-x: hidden;
    }
}


@media screen and (max-height: 600px) {
    .container {
        display: none;
    }

    .disclaimer {
        display: flex;
    }
}

/* error */

.error {
    color: red;
}

.error-container {
    position: fixed;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 30;
    background-color: white;
}

.error-object {
    width: 350px;

    min-width: 128px;
    min-height: 128px;

    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;
    border-radius: 4px;
}

.error-image {
    width: 256px;
    height: 256px;
    background-size: cover;
    background-position: center center;
    background-image: url(https://cdn.inschool.fi/2.33.34.2/nc3/img/error-pages/error-server-error.svg);
}

.error-object h1 {
    margin: 15px;
    color: black;
}

.error-object h2 {
    margin: 15px;
    color: rgb(30, 30, 30);
    text-align: center;
}

.error-object h3 {
    color: rgb(215, 215, 215);
    text-align: center;
}

.error-object h4 {
    padding: 10px;
    color: var(--font-h2);
}

.error-object h7 {
    padding: 10px;

    border-radius: 7px;

    background-color: rgb(47, 47, 47);
    color: rgb(200, 60, 60);
}

.error-object h5 {
    margin: 15px;
    padding: 7px;

    text-align: center;

    border-radius: 100px;

    font-family: 'semi-bold';
    font-size: 17px;
    color: white;

    background-color: rgb(200, 60, 60);
}