.content {
    position: absolute;
    width: 75%;
    height: 85%;


    display: flex;
    align-items: center;
    flex-direction: row;

    border-left: solid 10px var(--accent-main);

    background-color: var(--background-main);

    box-shadow: 0 0 15px var(--shadow-main);

    transition: all .3s ease-in-out
}

.side-bar {
    position: absolute;
    width: 250px;
    height: 95%;

    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-right: solid 1px var(--border-main);

    text-align: start;

    overflow-y: scroll;
    overflow-x: hidden;
}

.side-bar h1 {
    width: 90%;
    margin: 3px;
    margin-left: 5px;
    cursor: pointer;

    transition: opacity .2s ease-in-out;
    font-size: 17px;
}

.side-bar h2 {
    width: 90%;
    margin: 3px;
    padding-left: 3px;
    margin-left: 15px;

    transition: opacity .2s ease-in-out;
    font-size: 15px;
}

.side-bar h3 {
    width: 90%;
    margin: 2px;
    margin-left: 25px;

    font-size: 13px;
}

.side-bar h1:hover {
    opacity: 50%;
}

.settings-content {
    position: absolute;
    width: calc(100% - 250px);
    left: 250px;

    height: 100%;

    display: flex;
    flex-direction: column;

    overflow-y: scroll;

    z-index: 3;
}

.theme-list {
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    padding: 15px;
    margin: 10px;

    flex-wrap: wrap;
}

.theme {
    margin: 10px;
    width: 200px;
    height: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    overflow: hidden;

    box-shadow: 0 0 15px var(--shadow-main);
}

.theme:hover .theme-main {
    transform: scale(1.03);
}

.theme h6 {
    position: absolute;
    margin-top: 100px;
    margin-left: 170px;

    font-size: 11px;

    z-index: 3;

    background-color: var(--accent-main);
    color: var(--font-h4);
    padding: 4px;

    border-radius: 4px;

    display: none;
}

.theme.selected h6 {
    display: unset;
}

.theme-background {
    width: 220px;
    height: 120px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;

    pointer-events: none;
}

.theme-main {
    position: absolute;

    width: 120px;
    height: 80px;

    pointer-events: none;

    transition: transform .2s ease-in-out;
}

.theme-main h1 {
    font-size: 15px;
    margin: 5px;

    pointer-events: none;
}

.theme-main h2 {
    font-size: 12px;
    margin: 5px;

    pointer-events: none;
}

.theme .theme-new {
    user-select: none;
    -webkit-user-select: none;

    margin: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 7px;
}

.theme-window {
    position: absolute;
    width: 700px;
    height: 450px;

    background-color: var(--background-darker);
    box-shadow: 0 0 15px var(--shadow-main);

    border-left: solid 10px var(--accent-main);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 7;
}

.window-background {
    position: absolute;
    width: 700px;
    height: 450px;

    background-size: 350px 350px !important;
    z-index: -1;
    pointer-events: none;
}

.theme-window .title {
    position: absolute;
    font-size: 30px;
    margin: 20px;
    padding: 10px;
    top: 10px;
}

.theme-window h3 {
    max-width: 400px;
    text-align: center;
}

.theme-window button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: none;

    color: var(--font-h2);
    opacity: 50%;
    cursor: pointer;

    transition: opacity .2s ease-in-out;
}

.theme-window button:hover {
    opacity: 100%;
}

.editor-disclaimer {
    width: 100%;
    height: 400px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.editor-disclaimer h6 {
    font-size: 20px;
    text-align: center;
    width: 80%;

    font-family: 'semi-bold';
    opacity: 50%;
}

.disclaimer-background {
    width: 256px;
    height: 256px;
    background-image: url(../../assets/img/otakarhu.svg);
    background-position: center center;
    background-repeat: no-repeat;

    opacity: 50% !important;
}

.theme-options button:nth-child(1) {
    background-image: url(../../assets/img/edit.svg);
}

.theme-options button:nth-child(2) {
    background-image: url(../../assets/img/delete.svg);
}

.theme-options button:hover {
    border: solid red 2px;
}


.settings-content {
    filter: none;
}

.settings-content h1 {
    font-size: 17px;
}

.sections {
    border-left: 5px;
    margin: 10px;

    border-left: solid 3px var(--accent-main);
}

.editor-disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
}

.section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin: 20px;
    padding-bottom: 0px;
    margin-left: 10px;

    margin-bottom: 0;
    padding-bottom: 0;

    cursor: pointer;
}

.section-title .icon {
    padding: 2px;

    user-select: none;

    color: var(--font-h3);
    opacity: 0;
}

.section-title h1:hover {
    font-family: 'bold';
    opacity: 100%;
}

.section-title h1:hover~.icon {
    opacity: 85%;
}

.section-title h1 {
    margin: 2px;
    font-size: 17px;
    user-select: none;

    opacity: 75%;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    margin-left: 10px;
}

.section.collapsed {
    display: none;
}

.colors form {
    margin: 10px;
    padding: 10px;

    margin-left: 0;

    display: flex;
    flex-direction: column;
}

.color-loading-screen {
    width: 100%;
    height: 256px;
}

.colors form h2 {
    padding: 2px;
}

.colors form h3 {
    padding: 2px;
}

.color-input {
    width: 54px;
    margin: 5px;
    padding: 2px;

    border-radius: 0 !important;

    background: white;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

.opacity-input,
.number-input {
    width: 45px;
    margin: 5px;

    padding: 2px;
    border: solid 2px white;

    border-radius: 0 !important;

    background: black;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;

    color: white;
}

.preview {
    width: 450px;
    height: 300px;

    margin: 20px;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 4;

    border-left: solid 5px var(--accent-main);
}

.preview img {
    min-width: 480px;
    min-height: 330px;

    filter: var(--background-filter);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    z-index: 1;
}


.backgrounds h2 {
    margin: 10px;
    font-size: 15px;
}

.format-disclaimer {
    width: 50%;

    margin: 10px;

    margin-left: 20px;
    margin-top: -10px;
    padding-left: 15px;
}

.format-disclaimer h2 {
    margin: 0;
    padding: 0;
}

.format-disclaimer h3 {
    margin: 10px;
    margin-left: 0;
    padding: 4px;

    font-size: 14px;

    border-radius: 4px;

    width: 30px;

    text-align: center;

    color: var(--login-lighter);

    background-color: rgb(20, 20, 20);
}

.backgrounds form {
    margin: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.backgrounds input {
    width: 45px;
    font-family: 'semi-bold';
}

.background-input h4 {
    color: var(--error);
    margin: 10px;
    font-size: 12px;
}

.background-input {
    margin: 5px;

    display: flex;
    flex-direction: column !important;
    align-items: unset !important;

    border-left: solid 3px var(--accent-main);
    padding-left: 15px;
}

.background-input h2 {
    margin: 10px;
}

.background-input input {
    margin: 10px;
    padding: 5px;

    width: 50% !important;
    font-family: 'regular' !important;
    background-color: var(--background-darker);
}

.settings-help {
    position: absolute;
    width: 250px;
    height: 100%;

    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.settings-help .info {
    width: 80%;
    padding: 10px;
    margin: 20px;

    border-radius: 0 !important;
    border-left: solid 1px var(--border-main);
}

.info h1 {
    font-size: 17px;
    margin: 10px;
}

.info h2 {
    font-size: 16px;
    margin: 10px;
}

.info h3 {
    font-size: 14px;
    margin: 10px;
}

.settings-content .colors,
.settings-content .backgrounds,
.settings-content .theme-actions,
.settings-content .account,
.settings-content .advanced {
    width: calc(100% - 250px - 200px);
    height: auto;
    max-width: 800px;

    margin: 50px;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--border-main);
}

.account {
    width: calc(100% - 250px - 100px) !important;
    max-width: 700px;
}

.account .title {
    font-size: 17px;
    margin-bottom: 20px;
}

.sharing-menu {
    width: 100%;
    height: 40px;
    background-color: var(--accent-main);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    box-shadow: 0 0 15px var(--shadow-main);
}

.sharing-menu h5 {
    cursor: pointer;
}

.sharing-menu h5:hover {
    font-family: 'bold';
}

.c-selected {
    font-family: 'bold';
}

.sharing {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-left: solid 3px var(--accent-main);
}

.sharing-background {
    position: absolute;
    width: 256px;
    height: 256px;
}

.shared h1,
.sharing h1 {
    font-size: 20px;
    margin: 10px;
    padding: 10px;
}

.shared h3,
.sharing h3 {
    width: 80%;
    padding: 5px;
    font-size: 14px;
    text-align: center;
}

.sharing form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    width: 80%;
    margin: 5px;
}

.sharing form h2 {
    width: 90%;
    text-align: left;
    margin-left: 10px;
    font-size: 13px;

}

.agreed {
    opacity: 50%;
    pointer-events: none;
}

.sharing h3 strong {
    color: var(--font-h1);
}

.code-actions {
    width: 100%;
    height: 400px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.share,
.apply {
    width: 50%;
    height: 90%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.share {
    border-right: solid 1px var(--border-main);
}

.apply {
    border-left: solid 1px var(--border-main);
}

.share-loading-screen {
    width: 50%;
    height: 90%;
}

.shared-loading-screen {
    width: 330px;

    height: 415px;

    margin-left: 50%;

    border-left: solid 1px var(--border-main);
}

.apply h2,
.share h2 {
    text-align: center;
    margin: 15px;
    opacity: 75%;

    font-size: 13px;

    width: 300px;
}

.generate {
    width: 50%;

    border-right: solid 1px var(--border-main);

    width: 270px;

    background: linear-gradient(to top, var(--login-main), var(--login-lighter));
    transition: opacity .2s ease-in-out;

    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
}

#disabled {
    pointer-events: none;
    opacity: 60%;
}

.generate button {
    padding: 10px;
    background-color: transparent;
    color: var(--font-h5);
    cursor: pointer;
}

.generate input {
    padding: 10px;
    background-color: transparent;
    color: var(--font-h5);
    width: 200px;
}

.generate button:hover {
    opacity: 75%;
}

.generate h3 {
    margin-top: -60px;
    position: absolute;
}

.generate h4 {
    position: absolute;
    margin-top: 75px;
    color: var(--font-h2);
    opacity: 75%;

    cursor: pointer;
}

.generate h4:hover {
    opacity: 100%;
}

.copy {
    width: 20px;
    height: 20px;

    background-image: url(../../assets/img/external-link.svg);
    background-position: center center;
    background-size: 20px 20px;
    background-repeat: no-repeat;

    filter: brightness(0) invert();
}

.apply input {
    font-size: 17px;
    width: 210px;
    padding: 10px;
    margin: 10px;
    background-color: var(--background-darker);
    z-index: 4;
}

.apply button {
    background: linear-gradient(to top, var(--login-main), var(--login-lighter));
    transition: opacity .2s ease-in-out;
    color: var(--font-h5);
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.apply button h5 {
    color: var(--font-h5);
    transition: opacity .2s ease-in-out;
}

.apply button:hover h5 {
    opacity: 75%;
}

.apply h4 {
    font-size: 14px;
    color: var(--error);
    text-align: center;
}

.apply h5 {
    font-size: 14px;
    color: var(--login-lighter);
    text-align: center;
}

.shared {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-left: solid 3px var(--accent-main);
}

.shared .sharing-background {
    left: 120px;
}

.token-list {
    width: 330px;

    height: 415px;

    overflow-y: scroll;
    margin-left: 50%;

    border-left: solid 1px var(--border-main);
}

.token-object {
    margin: 10px;
    padding: 10px;
    border-bottom: solid 1px var(--border-main);
}

.token-object h3 {
    font-size: 11px;
    padding: 0;
    margin: 0;
    text-align: left;
    margin-left: 5px;

    opacity: 75%;
}

.token-object h6 {
    background-color: var(--login-lighter);
    width: 70px;
    text-align: center;
    padding: 3px;
    color: var(--font-h5);
    font-size: 12px;
    border-radius: 4px;
    margin-top: 10px;
}

.token-object .user {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user h2:nth-child(1) {
    margin: 5px;
    padding: 5px;
    border-radius: 25%;

    color: var(--font-h5);
    font-family: 'bold';
    background-color: var(--L2021-mandatory-main);
}

.user h2:nth-child(2) {

    font-family: 'bold';
    color: var(--L2021-mandatory-main);
}

.token-object button {
    margin-top: -7px;
    margin-left: 248px;

    background-color: transparent;
    font-size: 15px;

    color: var(--error);
    opacity: 50%;

    cursor: pointer;
    transition: opacity .2s ease-in-out;
}

.token-object button:hover {
    opacity: 100%;
}

.token-object .copyable {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--background-darker);
    width: 250px;
    margin-top: 5px;
}

.copyable .copy {
    position: unset;
    filter: brightness(0) invert(0) !important;
    margin: 0;
    padding: 0;

    cursor: pointer;
}

.copytext {
    text-align: center !important;
    font-size: 14px !important;
}

.copyable .copy:hover {
    opacity: 75%;
}

.copyable input {
    background-color: transparent;
    width: 210px;
    font-size: 17px;
    padding: 10px;
    padding-right: 0;
}

.credits-container {
    width: 100%;
    max-width: 1200px;

    margin: 10px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.credits-container .credits {
    margin: 10px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.credits h1 {
    text-align: center;
}

.credits h3 {
    padding-top: 5px;
    line-height: 1.2;
    font-style: italic;
    text-align: center;

    max-width: 400px;
}

.tester-list {
    width: 100%;
    max-width: 1200px;

    margin: 10px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title,
.tester-list h1 {
    font-size: 20px;
    text-align: center;
    padding: 10px;
    margin: 10px;
}

.credits-container .title {
    font-size: 25px;

    border-bottom: solid 1px var(--border-main);
}

.tester-list h3 {
    padding: 4px;
    font-size: 17px;
    font-style: italic;
}


.footer {
    position: absolute;
    bottom: 0 !important;

    height: 40px;
    width: 96%;
    left: 2%;
    padding-top: 5px;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-top: solid 1px var(--border-main);
}

.footer .ref {
    margin: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 30%;

    cursor: pointer;
}

.github {
    height: 20px;
    width: 20px;

    background-size: 20px 20px !important;
    background-repeat: no-repeat !important;
    background-image: url(../../assets/img/github.svg);
    filter: brightness(0);
}

.discord {
    height: 20px;
    width: 20px;

    background-size: 20px 20px !important;
    background-repeat: no-repeat !important;
    background-image: url(../../assets/img/discord.svg);
    filter: brightness(0);
}

.ref h4 {
    color: black;
}

@media screen and (max-width: 1600px) {
    .content {
        width: 98%;
    }
}


@media screen and (max-height: 950px) {
    .container {
        justify-content: flex-end;
    }

    .content {
        height: calc(100% - 50px - 5%);
        bottom: 2.5%;
    }
}

@media screen and (max-width: 1200px) {
    .content {
        display: none !important;
    }
}