.content {
    position: absolute;
    width: 75%;
    height: 85%;

    background-color: var(--background-main);

    box-shadow: 0 0 15px var(--shadow-main);

    border-left: solid 10px var(--accent-main);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.content-bulk {
    position: absolute;
    width: 75%;
    height: 85%;

    background-color: var(--background-main);

    box-shadow: 0 0 15px var(--shadow-main);

    border-left: solid 10px var(--accent-main);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}


.categories {
    position: absolute;
    width: 300px;
    height: 95%;

    left: 0;

    border-right: solid 1px var(--border-main);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.categories div {
    padding: 5px;
    margin: 10px;
    width: 80%;

    border-left: solid 3px var(--border-main);

    cursor: pointer;
}

.categories div:hover>h1 {
    opacity: 50%;
}

.categories div h1 {
    font-size: 20px;
    padding: 2px;
    padding-bottom: 5px;
}

.category-selected {
    border-left: solid 3px var(--accent-main) !important;
}

.action {
    width: 85%;
    background-color: var(--accent-main);
    color: var(--font-h5);
    padding: 5px;
    margin: 10px;

    max-width: 150px;

    font-family: 'semi-bold';

    cursor: pointer;
}

.action:hover {
    opacity: 0.7;
}

.categories .title {
    font-size: 20px;

    margin: 10px;
}

.messages {
    position: absolute;
    width: 300px;
    height: 95%;

    left: 300px;

    border-right: solid 1px var(--border-main);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-bulk .messages {
    display: none;
}

.list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
}

.message-object {
    margin: 10px;
    padding: 10px;

    width: 80%;

    border-left: solid 3px var(--accent-main);

    background-color: var(--background-main);

    box-shadow: 0 0 15px var(--shadow-main);
}

.message-object.Full,
.message-object.ExpiredReg {
    border-left: solid 3px var(--error) !important;
}

.message-object .calendar {
    width: 25px;
    height: 25px;

    background-image: url(../../assets/img/calendar.svg);
}

.message-object h1 {
    font-size: 17px;
    padding: 2px;

    cursor: pointer;

    transition: opacity .2s ease-in-out;
}

.message-object h1:hover {
    opacity: 50%;
}

.message-object h2 {
    padding: 2px;
}

.message-object h2:nth-child(2) {
    font-family: 'semi-bold';
}

.message-object h3 {
    padding-top: 8px;
    padding: 2px;
}

#selected {
    border-left: solid 3px var(--accent-main);
}

.message-content {
    position: absolute;
    left: calc(300px + 300px);
    width: calc(100% - (300px + 300px + 25px));
    height: 95%;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
}


.content-bulk .message-content {
    left: 300px;
    width: calc(100% - (300px + 25px));
}

.message-full {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 95%;
    height: auto;

    margin-bottom: 20px;
    padding-bottom: 20px;

    border-bottom: solid 3px var(--accent-main);
}

#new h1,
#new h2,
#new h3 {
    font-family: 'bold';
}

.message-object h6 {
    color: var(--font-h4);

    font-size: 12px;
    width: 25px;

    background-color: var(--accent-main);
    padding: 4px;
    margin: 5px;

    border-radius: 4px;
}

.message-content h1 {
    font-size: 27px;
    margin: 10px;
    padding: 10px;

    max-width: 1200px;
}

.message-content .info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 90%;

    padding-bottom: 40px;
    border-bottom: solid 1px var(--border-main);
}

.message-content .actions {
    width: 90%;
    margin: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    padding-bottom: 10px;

    border-bottom: solid 1px var(--border-main);
}

.actions .action {
    margin-right: 10px;
}

.message-content .info ul {
    margin: 20px;
}

.info ul a:nth-child(2) {
    font-family: 'semi-bold';
}

.main-content {
    font-size: 16px;
    padding: 20px;

    width: 90%;

    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: solid 1px var(--border-main);
}

.main-content * {
    color: var(--font-h2);
    font-family: 'arial';
    padding: 5px;
    line-height: 1.3;
}

.main-content strong,
.main-content b {
    font-weight: bold;
}

.main-content .wilma-link {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    transition: opacity .2s ease-in-out;
}

.wilma-link:hover {
    opacity: 50%;
}

.wilma-link .icon {
    width: 15px;
    height: 15px;
    background-image: url(../../assets/img/external-link.svg);
    background-size: cover;
    background-position: center center;
}

.wilma-link h6 {
    font-size: 12px;
}

.message-content .responses {
    width: 90%;
    margin-top: 20px;
}

.message-reply {
    margin: 5px;
    padding: 5px;
}

.message-reply.other {
    border-left: solid 3px var(--accent-main);
}

.message-reply.own {
    border-right: solid 3px var(--accent-main);
}

.responses div * {
    color: var(--font-h2);
    font-family: 'arial';
    font-size: 17px;
    padding: 5px;
}

.responses h4 {
    font-size: 12px;
    padding: 10px;

    font-family: 'regular';

    opacity: 75%;
}

.list-loading-screen {
    height: 100%;
    width: 300px;
}

.message-loading-screen {
    width: 100%;
    height: 100%;
}

.message-full-loading-screen {
    width: 100%;
    height: 50dvh;
}

.message-placeholder {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 1400px) {
    .content {
        width: 98%;
    }

    .categories {
        width: 200px;
    }

    .categories div h1 {
        font-size: 16px;
    }

    .messages {
        width: 220px;
        left: 200px;
    }

    .message-object h1 {
        font-size: 16px;
    }

    .message-object h2 {
        font-size: 14px;
    }

    .message-object h3 {
        font-size: 12px;
    }

    .list {
        width: 100%;

        overflow-x: hidden;
    }

    .message-content h1 {
        font-size: 18px;
        margin: 5px;
        padding: 5px;
    }

    .message-content .info {
        padding-bottom: 10px;
    }

    .message-content .info ul {
        margin: 5px;
    }

    .message-content .info ul a {
        font-size: 15px;
    }

    .message-content {
        width: calc(100% - 200px - 220px);
        left: calc(200px + 220px);
    }


    .content-bulk .message-content {
        left: 200px;
        width: calc(100% - (200px + 25px));
    }

    .loading-indicator.message {
        width: calc(100% - 200px - 220px);
        left: calc(200px + 220px);
    }

    .loading-indicator.list {
        width: 220px;
        left: 200px;
    }

    .main-content * {
        font-size: 14px;
    }

    .responses div * {
        font-size: 14px;
    }
}


@media screen and (max-height: 750px) {
    .container {
        justify-content: flex-end;
    }

    .content {
        height: calc(100% - 50px - 5%);
        bottom: 2.5%;
    }
}