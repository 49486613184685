.top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;

    background-color: var(--accent-main);
    box-shadow: 0 0 15px var(--shadow-main);

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    z-index: 5;
}


.top .links {
    width: calc(100% - 350px);
    margin-left: 350px;

    height: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.top.expanded .links {
    justify-content: flex-start;
}

.logo-text h1 {
    color: var(--font-h4);
    font-size: 30px;
    padding: 5px;
}

.top a {
    background: none;
    padding-left: 15px;
    padding-right: 15px;
    height: 50px;

    min-width: 75px;

    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.top a h5 {
    color: var(--font-h5);
    font-size: 17px;
    pointer-events: none;
}

.top a h1 {
    pointer-events: none;
}

.top .expand:hover,
.top a:hover {
    background-color: rgba(255, 255, 255, 0.174);
}

.top .user-info {
    position: absolute;
    width: 300px;
    height: 100%;

    left: 50px;

    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.user-info .user-data {
    position: absolute;
    width: 90%;
    height: 60px;

    margin-bottom: -25px;

    background-color: var(--background-main);

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.155);

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-info h1 {
    font-size: 20px;
    padding-left: 20px;
    padding-bottom: 2px;
}

.user-info h2 {
    font-size: 15px;
    padding-left: 25px;
}

.user-data .logout {
    position: absolute;
    right: 7px;
    bottom: 7px;

    font-size: 13px;

    opacity: 30%;
    color: var(--font-h2);

    cursor: pointer;
}

.user-data .logout a {
    margin: 0;
    padding: 0;
    height: auto;
}

.logout:hover {
    opacity: 100%;
}

.logout:hover a {
    background-color: transparent;
}

.expand {
    cursor: pointer;
    background: none;
    color: var(--font-h5);
    font-size: 25px;
    height: 50px;

    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.expand h1 {
    color: var(--font-h5);
    height: 50%;
    font-size: 20px;
    margin-bottom: 15px;
}

@media screen and (max-width: 1250px) {
    .top .user-info {
        display: none;
    }


    .top .links {
        margin-left: 0;
        width: 100%;
    }
}