.content {
    position: absolute;
    width: 75%;
    height: 85%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    border-left: solid 10px var(--accent-main);

    box-shadow: 0 0 15px var(--shadow-main);
}

.tray-list {
    position: absolute;
    height: 100%;
    width: 250px;

    left: 0;

    background-color: var(--background-main);

    border-right: solid 1px var(--border-main);

    overflow-y: scroll;
}

.tray-list .tray {
    margin: 20px;
}

.tray h1 {
    padding: 5px;
}

.tray .period-list {
    padding: 5px;

    border-left: solid 2px var(--border-main);
}

.tray-object {
    padding: 5px;

    cursor: pointer;
    user-select: none;
}

.tray-object h6 {
    width: 40px;
    text-align: center;
    margin-top: 5px;
    padding: 3px;

    background-color: var(--accent-main);
    border-radius: 4px;

    font-size: 10px;
    color: var(--font-h5);
}

.tray-object .closed {
    background-color: var(--error) !important;
}

.tray-object.selected h2 {
    font-family: 'bold';
}

.tray-object h2 {
    transition: opacity .2s ease-in-out;
}

.tray-object:hover h2 {
    opacity: 50%;
}

.tray-object h3 {
    font-size: 11px;
    padding: 2px;
}

.tray-loading-screen {
    width: 100%;
    height: 100%;
}

.tray-main {
    position: absolute;
    height: 100%;
    width: calc(100% - 250px - 350px);

    left: 250px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--background-main);

    overflow-y: scroll;
}

.mode-disclaimer {
    position: fixed;
    height: 30px;
    width: 110%;
    opacity: 50%;

    top: 50px;

    background-color: var(--accent-main);
    pointer-events: none;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mode-explanation {
    text-align: center;
    line-height: 1.1;
}

.mode-disclaimer h6 {
    min-width: 300px;
    height: 20px;

    font-size: 15px;
    padding-top: 4px;
    color: var(--font-h5);
}

h6 strong {
    font-family: 'bold';
}

.tray-main .period {
    width: 90%;
    height: auto;

    margin-top: 30px;

    background-color: var(--background-main);

    border-bottom: solid 1px var(--border-main);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.period h1 {
    font-size: 20px;
    margin: 10px;
    padding: 10px;

    border-bottom: solid 2px var(--border-main);
}

.period-actions {
    width: 80%;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.hide-period {
    width: 25px;
    height: 25px;

    background: none;

    display: flex;
    justify-content: center;
    align-items: center;
}

.hide-period:hover,
.disable-period:hover {
    transform: scale(1.1);
}

.hide-period h6 {
    width: 80%;
    height: 80%;
    padding-left: 10px;

    margin: 2px;

    background-image: url(../../assets/img/up.svg);
    background-position: center center;
    background-size: contain;

    transform: rotate(0);

    transition: transform .3s ease-in-out;
}

.hide-period .open {
    transform: rotate(180deg) !important;
}


.disable-period {
    width: 25px;
    height: 25px;

    margin: 2px;

    background: none;

    background-image: url(../../assets/img/close.svg);
    background-position: center center;
    background-size: contain;

    transition: transform .3s ease-in-out;
}


.period.open .bar {
    width: 90%;

    margin: 10px;

    display: flex;
    flex-direction: column;
}

.period.closed .bar {
    display: none;
}

.bar h2 {
    padding: 10px;
}

.bar .course-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.course-list div {
    margin: 2.5px;
    padding: 2.5px;

    cursor: pointer;

    box-shadow: 1px 1px rgba(0, 0, 0, 0.279);

    display: flex;
    justify-content: center;
    align-items: center;
}

.course-list .friend-list {
    position: absolute;
    margin-top: 25px;
    margin-left: 70px;

    width: 30px !important;

    box-shadow: none;
    pointer-events: none;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.friend-list h6 {
    position: absolute;
    padding: 3px;
    margin: 2px;
    text-align: center;

    box-shadow: 0 0 7.5px var(--shadow-main);
    color: var(--font-h5);

    font-size: 12px;
    border-radius: 25%;

    font-family: 'bold';
}

.friend-list h6:nth-child(1) {
    z-index: 3;
}

.friend-list h6:nth-child(2) {
    z-index: 2;
    margin-left: 17px;
    scale: 0.95;
}

.course-list div:hover>.course-data {
    opacity: 1;
}

.course-data {
    opacity: 0;
    margin-top: 100px !important;

    position: absolute;

    background-color: black;

    display: flex;
    flex-direction: column;

    transition: opacity .2s ease-in-out;

    pointer-events: none;
    z-index: 7;
}

.course-data h2 {
    padding: 2px;
    margin: 0;
    font-size: 13px;
    color: white;
    text-align: center;
}

.course-data h3 {
    padding: 2px;
    margin: 0;
    font-size: 12px;
    color: white;
    opacity: 75%;
    text-align: center;
}

.course-list div {
    color: var(--course-tray-h1);
    padding: 4px;
}

.course-list div.disa {
    text-decoration: line-through;
}

.course-list div.matched,
.course-list div.disa.matched {
    font-family: 'bold';
    color: red;
}

.course-list div.filtered,
.course-list div.disa.filtered {
    display: none;
}

/*
rgb(238, 238, 238) debugger eval code:21:11
-  ksuor-off disa debugger eval code:23:13
-  ksuor-off debugger eval code:23:13
rgb(191, 191, 255) debugger eval code:21:11
-  kF76F34DE_50868-off
*/

.ksuor-off,
.ksuor-on {
    background-color: rgb(178, 178, 178);
    opacity: 75% !important;

    border: solid 1px gray;
    box-shadow: none;
    color: rgb(90, 90, 90) !important;

    pointer-events: none;
    z-index: 0;
}

.ksuor-on {
    opacity: 100% !important;
    border: solid 3px black;
    pointer-events: all;

    background-color: rgb(115, 115, 115);
    color: var(--font-h4) !important;
    z-index: 1;
}

.kCFC108FF_50713-off,
.k70F27363_35245-off,
.k70F27363_35780-off,
.kAB14633F_1381-off,
.kAB14633F_1388-off,
.kB5DAF4F1_27576-off,
.k5C1BC722_1174-off,
.kD2F126D9_1381-off,
.kF76F34DE_50713-off {
    background-color: var(--L2021-mandatory-main);
}

.kCFC108FF_50717-off,
.k70F27363_35249-off,
.kB5DAF4F1_27580-off,
.kD2F126D9_1892-off,
.kF76F34DE_50717-off {
    background-color: var(--L2021-g-optional-main);
}

.kCFC108FF_50721-off,
.k70F27363_35253-off,
.kAB14633F_1519-off,
.kB5DAF4F1_29537-off,
.kB5DAF4F1_31827-off,
.kB5DAF4F1_31827-off,
.kD2F126D9_1945-off,
.kF76F34DE_50721-off {
    background-color: var(--L2021-l-optional-main);
}

.k70F27363_35763-off,
.k5C1BC722_1168-off {
    background-color: var(--L2016-l-optional-main);
}

.k70F27363_35783-off,
.k5C1BC722_1177-off,
.k5C1BC722_1660-off {
    background-color: var(--L2016-g-optional-main);
}

.kCFC108FF_50868-off,
.k5C1BC722_1188-off,
.k5C1BC722_1217-off,
.k5C1BC722_1662-off,
.k70F27363_35766-off,
.kF76F34DE_50868-off {
    background-color: var(--L2021-diploma-main);
}

/* k5C1BC722_1188-off */

.kCFC108FF_50713-on,
.k70F27363_35245-on,
.k70F27363_35780-on,
.kAB14633F_1381-on,
.kAB14633F_1388-on,
.kB5DAF4F1_27576-on,
.k5C1BC722_1174-on,
.kD2F126D9_1381-on,
.kF76F34DE_50713-on {
    background-color: var(--L2021-mandatory-selected);
    border: solid 3px black;

    box-shadow: none;
    color: white !important;
}

.kCFC108FF_50717-on,
.k70F27363_35249-on,
.kB5DAF4F1_27580-on,
.kD2F126D9_1892-on,
.kF76F34DE_50717-on {
    background-color: var(--L2021-g-optional-selected);
    border: solid 3px black;

    box-shadow: none;
    color: white !important;
}

.kCFC108FF_50721-on,
.k70F27363_35253-on,
.kAB14633F_1519-on,
.kD2F126D9_1945-on,
.kF76F34DE_50721-on {
    background-color: var(--L2016-l-optional-selected);
    border: solid 3px black;

    box-shadow: none;
    color: white !important;
}

.k70F27363_35763-on,
.k5C1BC722_1168-on {
    background-color: var(--L2016-l-optional-selected);
    border: solid 3px black;

    box-shadow: none;
    color: white !important;
}

.k70F27363_35783-on,
.k5C1BC722_1177-on,
.k5C1BC722_1660-on {
    background-color: var(--L2016-g-optional-selected);
    border: solid 3px black;

    box-shadow: none;
    color: white !important;
}

.kCFC108FF_50868-on,
.k5C1BC722_1188-on,
.k5C1BC722_1217-on,
.k5C1BC722_1662-on,
.k70F27363_35766-on,
.kF76F34DE_50868-on {
    background-color: var(--L2021-diploma-selected);
}

.tray-info {
    height: 100%;
    width: 350px;

    position: absolute;
    right: 0;

    border-left: solid 2px var(--border-main);

    background-color: var(--background-main);

    overflow-y: scroll;
}

.tray-info .edit-mode {
    background-color: var(--accent-main);
    height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    box-shadow: 0 0 15px var(--shadow-main);
}

.edit-mode h5 {
    cursor: pointer;
    user-select: none;
}

.edit-mode h5:hover {
    font-family: 'bold';
}

.edit-mode .selected {
    font-family: 'bold';
}

.tray-info-loading-screen {
    width: 100%;
    height: auto;
    min-height: 400px;
}

.tray-info h1 {
    font-size: 20px;
    margin: 10px;
    padding: 10px;

    border-bottom: solid 2px var(--border-main);
}

.tray-info h3 {
    margin: 5px;
}

.tray-info .filters {
    width: 100%;
    height: 50px;

    background-color: var(--accent-main);
    box-shadow: 0 0 15px var(--shadow-main);

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.filters h4 {
    color: var(--font-h5);
    cursor: pointer;
}

.filters h4:hover {
    font-family: 'bold';
}

.selected-filter {
    font-family: 'bold';
    pointer-events: none;
}

.filter-list {
    width: 90%;
    margin: 20px;
    height: 300px;
    padding-bottom: 5px;

    overflow-y: scroll;
    overflow-x: hidden;

    border-bottom: solid 1px var(--border-main);
}

.filter-info {
    padding-bottom: 5px;
    margin-bottom: 10px;

    border-bottom: solid 1px var(--border-main);

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.filter-info h3 {
    text-align: center;
    font-style: italic;
}

.filter-info h2 {
    opacity: 50%;

    font-size: 12px;

    width: 100%;
    text-align: center;

    cursor: pointer;

    transition: opacity .2s ease-in-out, color .2s ease-in-out;
}

.filter-info h2:nth-child(1):hover {
    opacity: 100%;
}


.filter-info h2:nth-child(2):hover {
    color: var(--error);
    opacity: 100%;
}

.tray-info form {
    margin: 20px;
}

.tray-info select {
    padding: 5px;
    margin: 5px;

    background: none;
    color: rgb(82, 82, 82);
}

.tray-info #filter-button {
    margin: 20px;
}

.friend-icon {
    margin: 5px;
    padding: 3px;

    border-radius: 5px;
    color: white;

    font-size: 13px;
    text-align: center;
}

.tray-info input {
    padding: 10px;
    margin: 5px;

    background-color: var(--background-main);
    box-shadow: 0 0 15px var(--shadow-main);

    font-style: italic;
    font-size: 15px;
}

.search-result {
    padding-left: 10px;
    margin-left: 10px !important;
    opacity: 75%;

    border-left: solid var(--font-h2);
}

.tray-info button {
    padding: 7px;
    margin: 10px;

    border-radius: 3px;
    color: white;
    background: linear-gradient(to bottom, var(--login-lighter), var(--login-main));

    transition: opacity .2s ease-in-out;
}

.course-action-apply h3 {
    font-size: 16px;
    padding: 0;
    margin: 0px;
    margin-right: 7px;

    color: white;
    opacity: 50%;

    font-family: 'bold';
}

.course-action-apply h2 {
    font-size: 16px;
    padding: 0;
    margin: 0;

    color: white;
}

.course-action-apply {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.course-action-apply.applied {
    opacity: 50%;
    pointer-events: none;
}

.tray-info button:hover {
    opacity: 75%;
}

.tray-info .course-info,
.tray-info .teacher-info {
    margin: 20px;
}

.course-info h1,
.teacher-info h1 {
    font-size: 17px;
    border: none;
    margin: 5px;
    padding: 0;
}

.error-text {
    color: var(--error);
}

.tray-info ul {
    margin: 10px;
}

.teacher-info ul {
    margin: 0;
    margin-left: 10px;

    padding: 4px;
    padding-left: 10px;

    border-left: solid 1px var(--border-main);
}

.tray-info ul a:nth-child(2) {
    font-family: 'semi-bold';
}

.tray-info ul a:nth-child(3) {
    color: var(--font-h3);
}

.Tavoitteet,
.Sisältö,
.Kuvaus {
    font-family: 'regular' !important;
    font-style: italic;
    line-height: 1.25;
    opacity: 75%;
}

.tray-placeholder {
    width: 100%;
    height: 100%;
}


.schedule {
    width: 300px;
    height: 200px;

    display: flex;
    flex-direction: row;

    margin: 10px;

    border-left: solid 5px var(--accent-main);
    padding-left: 1px;

    overflow-y: scroll;
}

.schedule-loading-screen {
    width: 300px;
    height: 200px;

    margin: 10px;

    border-left: solid 5px var(--accent-main);
    padding-left: 1px;

    background-image: url(../../assets/img/loading-book.gif);
    background-position: center center;
    background-size: 64px 64px;
    background-repeat: no-repeat;
}

.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error h6 {
    text-align: center;
    opacity: 50%;
    color: var(--font-h2);
    pointer-events: none;
    user-select: none;
}

.schedule .day {
    width: calc((100% / 5));
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hour {
    /* this was a mistake */
    position: absolute;

    width: calc((100% / 5 - 15px));
    min-height: 10px;

    margin-bottom: 5px;

    display: flex;
    justify-content: center;
    align-items: center;


    border: solid 1px var(--border-main);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.data {
    height: 90%;
    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.data .group {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.data h2 {
    text-shadow: 0 0 7px var(--shadow-darker);
    color: var(--font-h2);
    font-size: 10px;
    padding: 5px;

    text-decoration: none;

    pointer-events: all;
    font-family: 'semi-bold';
    transition: opacity .2s ease-in-out;
}

button:disabled {
    opacity: 75% !important;
    pointer-events: none;
}

.error-window {
    position: absolute;
    width: 350px;
    height: auto;

    background-color: var(--background-darker);
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-left: solid 10px var(--accent-main);

    box-shadow: 0 0 15px var(--shadow-main);
    padding-bottom: 40px;
}

.error-window .background {
    position: absolute;
    width: 100%;
    height: 80%;
    background-image: url(../../assets/img/otakarhu-confused.svg);
    background-size: contain;

    opacity: 10%;
    background-position: center center;
    background-repeat: no-repeat;
}

.error-window h1 {
    color: var(--font-h1);
    font-size: 25px;
    text-align: center;
    margin: 15px;
}

.error-window h2 {
    text-align: center;
    width: 70%;

    font-family: 'regular';
    font-style: italic;
}

.error-window h4 {
    position: absolute;
    opacity: 50%;

    bottom: 10px;

    font-size: 14px;
    color: var(--font-h2);

    cursor: pointer;
}

.error-window h4:hover {
    opacity: 75%;
}

@media screen and (max-width: 1400px) {
    .content {
        width: 98%;
    }
}

@media screen and (max-width: 1100px) {
    .tray-list {
        width: 200px;
    }

    .tray-main {
        width: calc(100% - 200px - 240px);
        left: 200px;
    }

    .bar {
        width: 90%;
    }

    .tray-info {
        width: 240px;
    }

    .schedule {
        width: 200px;
    }

    .schedule .day {
        width: 40px;
    }

    .day .hour {
        width: 40px;
        min-height: 20px;
    }

    .hour .data {
        min-height: 17.5px;
    }

    .data h5 {
        font-size: 8px;
    }

    .tray-info h1 {
        font-size: 16px;
    }

    .tray-info a {
        font-size: 14px;
    }
}


@media screen and (max-height: 750px) {
    .container {
        justify-content: flex-end;
    }

    .content {
        height: calc(100% - 50px - 5%);
        bottom: 2.5%;
    }

    .mode-disclaimer {
        height: 87%;
    }
}