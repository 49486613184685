.error {
    color: red;
}

.error-container {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;
    z-index: 30;
}

.error-image {
    width: 256px;
    height: 256px;
    background-size: cover;
    background-position: center center;
    background-image: url(https://cdn.inschool.fi/2.33.34.2/nc3/img/error-pages/error-server-error.svg);
}

.error-object {
    width: 350px;

    min-width: 128px;
    min-height: 128px;

    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;
    border-radius: 4px;
}

.error-object h1 {
    margin: 15px;
    color: black;
    text-align: center;
}

.error-object h3 {
    color: rgb(215, 215, 215);
    text-align: center;
}

.error-object h2 {
    margin: 15px;
    color: rgb(30, 30, 30);
    text-align: center;
}


.error-object h4 {
    padding: 10px;
    color: var(--font-h2);
}

.error-object h6 {
    padding: 10px;

    border-radius: 7px;

    background-color: rgb(47, 47, 47);
    color: rgb(200, 60, 60);
}

.error-object h5 {
    margin: 15px;
    padding: 7px;

    text-align: center;

    border-radius: 100px;

    font-family: 'semi-bold';
    font-size: 17px;
    color: white;

    background-color: rgb(200, 60, 60);
}